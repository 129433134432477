<template>
	<pui-datatable :modelName="model" readOnly :showRowActions="false"></pui-datatable>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'itinerariotecnico-grid',
	data() {
		return {
			model: 'itinerariotecnico'
		};
	},

	methods: {}
};
</script>

<style lang="postcss" scoped>
</style>
